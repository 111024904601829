.categoryName {
  display: flex;
  height: 3em;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  align-items: center;
  border-radius: 0.3rem;
  margin-top: 1rem;
}

.categoryNameIcons {
  display: flex;
  gap: 1rem;
  margin-left: auto;
  padding: 0 1rem;
  align-items: center;
}

.categoryNameIc {
  margin-right: 1rem;
}

.rotateDropdownArrow {
  transform: rotate(180deg);
}

.onHover:hover {
  cursor: pointer;
  opacity: 0.6;
}
