.ps__rail-x {
  top: 0;
}

.bracket-scroll {
  position: relative;
  width: 100%;
}

#bracket-grid {
  background: #fff;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
}

.bracket-inner {
  position: relative;
}

.block {
  display: flex;
  width: fit-content;
  margin: 0 0.625rem;
  position: relative;
}

.rounds {
  display: flex;
  min-width: 100%;
  background: #fff;
}

.round {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 16.875em;
}

.round-name {
  display: flex;
  background: #ced4e0;
  color: #fff;
  width: 3.75em;
  height: 1.875em;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  position: absolute;
  z-index: 1;
}

.skirmish {
  height: 72px;
  width: 15.625em;
  border: 1px solid #8996ac;
  border-radius: 0.3rem;
  display: flex;
}

.skirmish-name {
  color: #005fee;
  font-weight: 600;
  width: 21px;
  height: fit-content;
  transform: rotate(-90deg);
  align-self: center;
}

.participants {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #8996ac;
  height: 100%;
  width: calc(100% - 21px);
}

.participants > :first-child {
  border-bottom: 1px solid #8996ac;
}

.participant {
  color: #262441;
  height: 50%;
  padding: 0.2rem 0.3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.participant.active:hover {
  cursor: pointer;
  background: rgb(137, 150, 172, 0.4);
  color: #fff;
}

.swap {
  background: rgb(137, 150, 172, 0.7);
  color: #fff;
}

.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connections {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  pointer-events: none;
}

.skirmish .round-name-badge {
  position: absolute;
  top: 25px;
  left: -28px;
  background-color: #f5bc4a;
  color: #262441;
  padding: 2px;
  border-radius: 5px;
  font-size: 13px;
  width: 26px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.connections path {
  stroke-width: 0.5px;
  stroke: #8996ac;
  fill: transparent;
  transition: stroke 0.3s ease;
}

.border_participant {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-right: 0.1em solid #8996ac;
}

.participant_winner_circle {
  position: absolute;
  display: flex;
  right: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(0, 95, 238);
}

.ellipsis {
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.participant_name {
  font-size: 12px;
}
