/* General Wrapper */
.wrapper {
  display: grid;
  gap: 0.5rem;
  padding: 1rem;
  background-color: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rowWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid #a2abba;
  border-radius: 8px;
}

/* Order */
.order {
  background-color: #fff;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  align-content: center;
}

/* Participant Number */
.participantNumber {
  background: #ced4e0;
  flex: 1;
  text-align: center;
  align-content: center;
  font-size: 1rem;
  height: 100%;
}

/* Small Gap */
.gap {
  width: 10px;
  background: #fff;
  height: 100%;
}

/* Name and City */
.participantAbout {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ced4e0;
  height: 100%;
  padding: 0 10px;
}

.name {
  font-size: 1rem;
  font-weight: 500;
}

.additionalInfo {
  font-size: 0.9rem;
}

/* Notes Container (5 columns) */
.notesWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  height: 100%;
}

.noteCell {
  padding: 10px;
  text-align: center;
  border-right: 1px solid #a2abba;
  display: grid;
  height: 100%;
}

/* Additional Columns (4 columns) */
.additionalColumns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  height: 100%;
}

.additionalCell {
  display: grid;
  height: 100%;
  padding: 10px;
  text-align: center;
}

.label {
  font-size: 0.8rem;
  color: grey;
}

.value {
  font-size: 1rem;
  color: black;
}

/* Mobile View */
@media (max-width: 767px) {
  .rowWrapper {
    flex-direction: column;
  }

  .order {
    flex: 0 0 100%;
    font-size: 1.5rem;
    text-align: center;
    padding: 20px 0;
  }

  .participantAbout {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .notesContainer {
    grid-template-columns: 1fr 1fr;
  }

  .additionalColumns {
    grid-template-columns: 1fr 1fr;
  }
}

/* Tablet View (Width <= 1024px) */
@media (max-width: 1024px) {
  .rowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns for tablet view */
    grid-gap: 10px;
  }

  .order {
    grid-column: span 2; /* Span order across both columns */
    text-align: center;
    font-weight: bold;
  }

  .firstColumn {
    /* First column with name and city */
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .secondColumn {
    /* Second column with notes and additional columns */
    grid-column: 2 / 3;
  }

  /* Ensure Name and City are inline */
  .participantAbout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .name {
    font-size: 1.2rem;
  }

  .additionalInfo {
    font-size: 0.8rem;
    color: gray;
  }

  /* Notes and Additional Columns */
  .notesWrapper, .additionalColumns {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns in the notes and additional columns */
    grid-gap: 10px;
  }

  .noteCell, .additionalCell {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  .label {
    font-size: 0.9rem;
    color: #888;
  }

  .value {
    font-size: 1rem;
    color: black;
  }

  /* Adjust the label and value style for the second column */
  .additionalCell {
    border-left: none;
    border-right: none;
  }
}



/* Desktop View */
@media (min-width: 1025px) {
  .rowWrapper {
    flex-direction: row;
    align-items: center;
  }

  .notesContainer {
    grid-template-columns: repeat(5, 1fr);
  }

  .additionalColumns {
    grid-template-columns: repeat(4, 1fr);
  }
}
